import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { ConfigModel } from '@connect/shared/models/config.model';

@Injectable()
export class BaseHttpClientService {
    private apiHost: string;

    constructor(
        public client: HttpClient,
        config: ConfigModel
    ) {
        let hostUri = config.api.host;

        if (true == hostUri.endsWith('/')) {
            hostUri = hostUri.substring(0, hostUri.length - 1);
        }

        this.apiHost = hostUri;
    }

    public getBaseHeaders(): Observable<HttpHeaders | never> {
        return of(new HttpHeaders());
    }

    public constructAbsoluteUri = (route: string): string => {
        if (true == route.startsWith('/')) {
            route = route.substring(1);
        }

        return `${this.apiHost}/${route}`;
    }
}
