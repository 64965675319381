import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from '@connect/secure/services/base.service';
import { ClientModel } from '@connect/contact/models/client.model';
import { BaseHttpClientService } from '@connect/shared/services/base-http-client.service';

@Injectable()
export class ClientService extends BaseService {
    constructor(httpClientService: BaseHttpClientService) {
        super(httpClientService);
    }

    public getClient = (clientId: string): Observable<ClientModel> => {
        return this.get<ClientModel>(`/api/clients/${clientId}`);
    }

    public saveClient = (model: ClientModel): Observable<ClientModel> => {
        return this.post<ClientModel>(`/api/clients`, model);
    }
}
