import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { DropdownItemModel } from '@connect/shared/models/dropdown-item.model';
import { ChildRelationshipTypeModel } from '@connect/contact/models/child-relationship-type.model';
import { ContactClientTypeModel } from '@connect/contact/models/contact-client-type.model';
import { JurisdictionTypeModel } from '@connect/secure/models/jurisdiction-type.model';
import { LegalMatterTypeModel } from '@connect/secure/models/legal-matter.model';
import { MaritalStatusTypeModel } from '@connect/contact/models/marital-status-type.model';
import { BaseService } from '@connect/secure/services/base.service';
import { DocumentTypeModel } from '@connect/secure/models/document-type.model';
import { DocumentFolderTypeModel } from '@connect/secure/models/document-folder-type.model';
import { ContactMethodTypeModel } from '@connect/contact/models/contact-method-type.model';
import { ContactStatusTypeModel } from '@connect/contact/models/contact-status-type.model';
import { AddressTypeModel } from '@connect/contact/models/address-type.model';
import { ContactPhaseTypeModel } from '@connect/contact/models/contact-phase-type.model';
import { PaymentTypeModel } from '@connect/finance/models/payment-type.model';
import { PaymentSourceTypeModel } from '@connect/finance/models/payment-source-type.model';
import { BookingStatusTypeModel } from '@connect/contact/models/booking-status-type.model';
import { InsuranceTicketStatusTypeModel } from '@connect/contact/models/insurance-ticket-status-type.model';
import { UserModel } from '@connect/secure/models/user.model';
import { ApplicationUserType } from '@connect/secure/models/enums/application-user-type.enum';
import { BaseHttpClientService } from '@connect/shared/services/base-http-client.service';

@Injectable()
export class LookupService extends BaseService {
    constructor(httpClientService: BaseHttpClientService) {
        super(httpClientService);
    }

    public getAddressTypes = () : Observable<AddressTypeModel[]> => {
        return this.get<AddressTypeModel[]>('api/config/address-types');
    }

    public getContactMethodTypes = (): Observable<ContactMethodTypeModel[]> => {
        return this.get<ContactMethodTypeModel[]>('/api/config/contact-method-types');
    }

    public getContactStatusTypes = (): Observable<ContactStatusTypeModel[]> => {
        return this.get<ContactStatusTypeModel[]>('/api/config/contact-status-types');
    }

    public getChildRelationshipTypes = (contactId?: string | null): Observable<ChildRelationshipTypeModel[]> => {
        let params = new HttpParams();

        if (null != contactId) {
            params = params.append('contact', contactId);
        }

        return this.get<ChildRelationshipTypeModel[]>('/api/config/child-relationship-types', params);
    }

    public getContactPhaseTypes = (): Observable<Array<ContactPhaseTypeModel>> => {
        return this.get<Array<ContactPhaseTypeModel>>('/api/config/contact-phase-types')
    }

    public getDocumentTypes = (): Observable<Array<DocumentTypeModel>> => {
        return this.get<Array<DocumentTypeModel>>('/api/config/document-types');
    }

    public getDocumentFolderTypes = (): Observable<Array<DocumentFolderTypeModel>> => {
        return this.get<Array<DocumentFolderTypeModel>>('api/config/document-folder-types');
    }

    public getJurisdictions = (): Observable<JurisdictionTypeModel[]> => {
        return this.get<JurisdictionTypeModel[]>('/api/jurisdictions');
    }

    public getLegalMatters = (): Observable<LegalMatterTypeModel[]> => {
        return this.get<LegalMatterTypeModel[]>('/api/config/legal-matter-types');
    }

    public getMaritalStatusTypes = (): Observable<MaritalStatusTypeModel[]> => {
        return this.get<MaritalStatusTypeModel[]>('/api/config/marital-status-types');
    }

    public getBookingStatusTypes = (): Observable<BookingStatusTypeModel[]> => {
        return this.get<BookingStatusTypeModel[]>('/api/config/booking-status-types');
    }

    public getInsuranceTicketStatusTypes = (): Observable<InsuranceTicketStatusTypeModel[]> => {
        return this.get<InsuranceTicketStatusTypeModel[]>('/api/config/insurance-ticket-status-types');
    }

    public getAttorneys = (): Observable<UserModel[]> => {
        let params = new HttpParams();
        params = params.append('type', ApplicationUserType.Attorney);

        return this.get<UserModel[]>('/api/users', params);
    }

    public getStaffMembers = (): Observable<UserModel[]> => {
        let params = new HttpParams();
        params = params.append('type', ApplicationUserType.Staff);

        return this.get<UserModel[]>('/api/users', params);
    }

    public getUsers = (): Observable<UserModel[]> => {
        return this.get<UserModel[]>('/api/users');
    }

    public getTimespanTypes = (): Observable<DropdownItemModel<number>[]> => {
        return of([
            { name: '1 Day', id: 1 },
            { name: '3 Days', id: 2 },
            { name: '7 Days', id: 3 },
            { name: '15 Days', id: 4 },
            { name: '30 Days', id: 5 },
            { name: '90 Days', id: 6 },
        ]);
    }

    public getClientSourceTypes = (): Observable<DropdownItemModel<number>[]> => {
        return of([
            { name: 'Friend', id: 1},
            { name: 'Internet', id: 2 },
            { name: 'Other', id: 3 },
        ]);
    }

    public getContactClientTypes = (): Observable<Array<ContactClientTypeModel>> => {
        return this.get<Array<ContactClientTypeModel>>('/api/config/contact-client-types');
    }

    public getPaymentSourceTypes = (): Observable<Array<PaymentSourceTypeModel>> => {
        return this.get<Array<ContactClientTypeModel>>('/api/config/payment-source-types');
    }

    public getPaymentTypes = (): Observable<Array<PaymentTypeModel>> => {
        return this.get<Array<ContactClientTypeModel>>('/api/config/payment-types');
    }
}
